.leaflet-container {
  display: flex;
  justify-content: center;
}

.leaflet-tooltip {
  background: #27303f;
  border-radius: 3px;
  border: 0;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  padding: 10px 17px 10px 17px;

  -webkit-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 3px 3px 7px 0px rgba(0, 0, 0, 0.55);
}

.z-index-1000 {
  z-index: 1000;
}

.leaflet-control-container {
  position: absolute;
  bottom: 20px;
  right: 10px;
}

.leaflet-left .leaflet-control {
  border: 0;
  position: absolute;
  bottom: 0;
  right: 0;
}

.leaflet-touch .leaflet-bar a {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
